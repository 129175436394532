/*------------------------------------*\
  # Toasts
\*------------------------------------*/

.Toastify__toast {
    &--success {
        background: $color-green !important;
    }
    &--warning,
    &--error {
        background: $color-error !important;
    }
}

