.bay-status {
  background-color: $color-black;

  &__filters {
    background-color: $color-lighterlack;
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    padding: 0 2rem;
    border-bottom: 2px solid $color-white;
  }

  &__option {
    width: 100%;
    text-transform: uppercase;
    padding: 2rem 0 0.5rem;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: -2px;

    &--selected {
      border-bottom: 2px solid $color-blue;
    }
  }

  &__reservations {
    padding: 2rem;
    overflow-y: scroll;
    height: calc(100vh - 150px);
  }

  &__bay {
    margin-bottom: 2rem;
    display: flex;
    position: relative;

    &--disabled {
      opacity: 0.4;
    }
  }

  &__image {
    margin-right: 2rem;
    width: 40px;
  }

  &__number {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: bold;
  }

  &__info {
    font-weight: bold;
    font-size: 1.2rem;
  }

  &__value {
    font-weight: normal;
  }

  &__new {
    background-color: $color-red;
    padding: 0.1rem 1rem;
    margin-left: 1rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    color: $color-black;
  }

  &__arrow {
    background-color: $color-blue;
    padding: 0.1rem 1rem;
    margin-left: 1rem;
    border-radius: 0.25rem;
    font-size: 1.2rem;
  }

  &__bike-status {
    color: $color-red;
    margin-right: 1rem;
    border-radius: 0.25rem;
    font-size: 1.4rem;
    position: absolute;
    right: 0;
  }
}
