
.login {
  background-color: $color-black;

  &.form {
    padding-top: 2rem;
    text-align: center;
  }

  &__logo {
    max-width: 200px;
  }

  &__title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 2rem;

    &--small {
      font-size: 1.2rem;
    }

    &--left {
      text-align: left;
    }

    &--mb-4 {
      margin-bottom: 4rem;
    }
  }

  &__background {
    background: url('../../images/brompton-login.png') no-repeat center center;
    background-size: cover;
    height: 40%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
