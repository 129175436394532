/*------------------------------------*\
  # HEADINGS
\*------------------------------------*/

/**
 * Simple default styles for headings 1 through 6.
 */

$headings : (h1: 4.2rem,
    h2: 2.8rem,
    h3: 2.4rem,
    h4: 2rem,
    h5: 1.8rem,
    h6 : 1.6rem,
) !default;

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    display: block;
    font-weight: $font-weight-bold;
    margin: 0 0 1rem;
}

@mixin make-headings($headings) {

    @each $heading-name,
    $heading-value in $headings {

        #{$heading-name},
        .#{$heading-name} {
            font-size: calc(#{$heading-value}/1.4);

            @include responsive(sm) {
                font-size: calc((#{$heading-value})/1.2);
            }

            @include responsive(md) {
                font-size: calc((#{$heading-value}));
            }
        }
    }
}

@include make-headings($headings);