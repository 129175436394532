/*----------------------------------*\
  # COLORS
\*----------------------------------*/

// Set up a colour palette which allows us to theme the entire project from one
// location.

// BBH Colours

$color-white: #fff;
$color-lightGry: #f7f7f7;
$color-grey: #cccccc;
$color-darkGre: #a9a9a9;
$color-black: #000;
$color-lighterlack: #323232;
$color-lightBlck: #242424;
$color-blue: #39a7c9;
$color-darkBlu: #267e99;
$color-green: #c9e065;
$color-orange: #ea702d;
$color-red: #eb002f;
$color-navy: #251f43;
$color-error: #CC0033;

// Colour palette.
$color-grey: #aaaaaa !default;
$color-primary: $color-blue !default;
$color-pickled-bluewood: #263643 !default;
$color-light-grey: $color-grey !default;
$color-darker-grey: $color-lighterlack !default;
$color-off-white: $color-lightGry;

$color-primary: $color-blue !default;
$color-secondary: $color-darkBlu !default;

// Theme colors.
$color-links: $color-blue;
$color-page-background: $color-black;
$color-page-text: $color-white;

// Buttons
