/*------------------------------------*\
  # PAGE
\*------------------------------------*/

/**
 * Page-level styling (e.g. HTML and BODY elements).
 */

:root {
    font-family: $font-family-base;
    font-size: $global-font-size;
    font-weight: $font-weight-normal;
    line-height: $global-line-height;
    background-color: $color-page-background;
    color: $color-page-text;
}

html, body, .container, #root {
    height: 100%;
}

body {
    @include font-size(16); // Set the body size to 16px
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    line-height: 1.8;
    padding: 0 0 2rem;

    @include responsive(md) {
        padding: 0 0 3rem;
    }
}

ul {
    margin: 0 0 2rem 1.5rem;
    list-style: disc;
    line-height: 1.8;

    @include responsive(md) {
        margin: 0 0 2rem 3rem;
    }
}

li {
    margin: 0 0 .5rem;
}

hr {
    border: 0;
    border-top: 0.1rem solid #cacaca;
    margin: 0 0 2rem;
    width: 100%;

    @include responsive(sm) {
        margin: 0 0 3rem;
    }

    @include responsive(md) {
        margin: 3rem 0 6rem;
    }
}

address {
    font-style: normal;
}

blockquote {
    display: block;
    border-width: 0.2rem 0;
    border-style: solid;
    border-color: #e6e4e4;
    font-style: italic;
    padding: 2.5rem 0 0.5rem;
    margin: 1.5rem auto 3.5rem;
    position: relative;
    width: 80%;

    &:before {
        @include font-size(80);
        content: "\201C";
        background: $color-white;
        color: #636363;
        height: 6rem;
        font-style: normal;
        left: 50%;
        position: absolute;
        text-align: center;
        top: 0em;
        transform: translate(-50%, -50%);
        width: 4rem;
    }
}

b,
strong {
    font-weight: $font-weight-bold;
}

:focus {
    outline: none;
}

.container {
    max-width: 1024px;
    max-height: 1024px;
    margin: 0px auto;
    position: relative;
}
