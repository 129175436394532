.bay {
  background-color: $color-black;

  input[type="number"], &__number {
    font-size: 8rem;
    background-color: $color-white;
    margin: 0 auto;
    color: $color-black;
    text-align: center;
  }

  &__text {
    font-size: 4.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
}
