.list-item {
  padding: 1.5rem 0;
  margin-left: 2rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid $color-lighterlack;

  @include responsive(sm) {
    max-width: $sm-max-width;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &__icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    border: 0.1rem solid $color-white;
    margin-right: 1rem;

    &--completed {
      background-color: $color-blue;
      border: 0.1rem solid $color-blue;
      text-align: center;

      &:after {
        content: "\2713";
        color: $color-white;
        font-weight: $font-weight-bold;
      }
    }
  }

  &__arrow {
    padding-right: 2rem;
  }
}
