
.error {
  background-color: $color-black;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 300px);
  padding: 0 2rem;

  &__icon {
    color: $color-red;
    font-size: 6rem;
  }

  &__heading {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  &__description {
    font-size: 1.2rem;
    padding: 0 4rem;
  }

  &__button {
    position: absolute;
    bottom: 8rem;
    left: 0;
    right: 0;
    padding: 0 2rem;
  }
}
