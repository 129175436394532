
.staff {
  background-color: $color-black;

  &__options {
    position: absolute;
    bottom: 8rem;
    left: 0;
    right: 0;
    padding: 0 4rem;
  }
}
