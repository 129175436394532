/*------------------------------------*\
  # TYPOGRAPHY
\*------------------------------------*/


/**
 * Typography variables
 */

$font-weight-light: 300;
$font-weight-normal: normal;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-black: 800;

// Family
$font-family-base: sans-serif;

// Animation
$ease-in: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-out: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-in-out: cubic-bezier(0.445, 0.05, 0.55, 0.95);
