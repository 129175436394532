
.qr-code {
  background-color: $color-black;

  &__image {
    text-align: center;
    margin: 4rem auto;
    max-width: 20rem;
  }

  &__text {
    font-size: 1.2rem;
    text-align: center;
    margin: 0 2rem 2rem 2rem;
  }

  &__error {
    text-align: center;
  }

  &__error-icon {
    color: $color-red;
    font-size: 3rem;
  }

  &__error-description {
    font-size: 1.2rem;
    padding: 0 4rem;
  }
}
