/*------------------------------------*\
  # FORM
\*------------------------------------*/

/**
 * Default styles for forms.
 */

.btn {
    padding: 1.5rem 2rem;
    background-color: $color-darker-grey;
    border: 0;
    display: block;
    width: 100%;
    border-radius: $global-radius;
    color: $color-white;
    margin-bottom: 2rem;
    font-weight: $font-weight-bold;
}

.btn-small {
    width: auto;

    &--1x {
        padding: 0.8rem 1rem;
        font-size: 1.2rem;
        border-radius: 0.5rem;
        margin: 0;
    }

}

.btn-primary {
    background-color: $color-primary;
}
