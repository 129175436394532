/*------------------------------------*\
  # LINKS
\*------------------------------------*/

/**
 * Default styles for simple hyperlinks.
 */

button,
a {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

a {
    color: $color-links;
    text-decoration: none;

    @include hocus() {
        text-decoration: underline;
    }
}