
.header {
  background-color: $color-black;
  padding: 2rem;
  color: $color-white;
  position: relative;
  display: flex;

  &--transparent {
    background: transparent;
    padding: 0;
  }

  &__title {
    text-align: center;
    font-weight: $font-weight-bold;
    width: 100%;
  }

  &__bell {
    color: $color-white;
    position: relative;
  }

  &__alert {
    width: 7px;
    height: 7px;
    background-color: $color-red;
    position: absolute;
    right: 0px;
    border-radius: 7px;
  }

  &__heading {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  &__description {
    font-size: 1.2rem;
  }

  &__background {
    background: url('../../images/banner-dock.png') no-repeat center center;
    background-size: cover;
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__intro {
    padding: 2rem 6rem;
    text-align: center;
  }

  &__logo {
    max-width: 200px;
  }

  &__back {
    color: $color-white;
  }

  &__home, &__logout {
    position: absolute;
    top: 1rem;
    padding: 1rem;
    color: $color-white;
  }

  &__home {
    right: 4rem;
  }

  &__logout {
    right: 1rem;
  }

  &__step {
    position: absolute;
    z-index: 2;
    font-size: 5rem;

    &--small {
      font-size: 2.5rem;
    }
  }
}
