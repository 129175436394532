
@keyframes spinner {
  to {transform: rotate(360deg);}
}

.loader {
  display: flex;
  height: 100%;
  justify-content: center;
  font-size: 4rem;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 10;

  i {
    animation: spinner .6s linear infinite;
  }
}
