.u-loading {
  &:before {
    content: '';
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: .5rem solid $color-light-grey;
    border-top-color: $color-primary;
    animation: spinner .6s linear infinite;
    z-index: $zindex-override;
  }

  &:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: $zindex-max;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
