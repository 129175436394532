
.dashboard {
  background-color: $color-black;

  &__options {
    padding: 2rem;

    .btn {
      margin-bottom: 4rem;
    }
  }
}
