
.rfid {
  background-color: $color-black;

  &__input {
    font-size: 4rem;
    margin: 0 auto;
    padding: 0 0.5rem;
    color: $color-white;
    text-align: center;
  }

  &__details {
    text-align: center;
  }

  &__bay {
    font-weight: bold;
  }
}
